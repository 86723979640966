import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Correct import statement
import Main from './layouts/Main'; // Fallback for lazy pages
import './static/css/main.scss'; // All of our styles

// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-TCYDPNRSTV');

const { PUBLIC_URL } = process.env;

// Lazy-loaded components
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Index = React.lazy(() => import('./pages/Index'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Projects = React.lazy(() => import('./pages/Projects'));
const Resume = React.lazy(() => import('./pages/Resume'));
const Stats = React.lazy(() => import('./pages/Stats'));

const App = () => {
  useEffect(() => {
    // Track initial pageview
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <React.Suspense fallback={<Main />}>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
